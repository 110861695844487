@import url("https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,400;1,500;1,600;1,700&display=swap");

/* width */
::-webkit-scrollbar {
  width: 5px !important;
}

/* Track */
::-webkit-scrollbar-track {
  background: #0c1851 !important;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #ebedf2 !important;
  border-radius: 20px !important;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #ebedf2 !important;
}
